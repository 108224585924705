import React from "react";
import styled, { css } from "styled-components";
import { NavLink } from "Components/NavLink";
import { MenuMode, useMenuContext } from "Components/MenuProvider";
import { Body2 } from "melodies-source/Text";

interface Props {
  icon: React.ReactNode;
  label: string;
  caption?: string;
  to?: string;
  onClick?: () => void;
  exact?: boolean;
}

export type LinkItem = Props;

export const Link: React.FC<Props> = ({
  icon,
  label,
  caption,
  to,
  onClick,
  exact,
}) => {
  const { menuMode } = useMenuContext();
  const Component = to ? NavLink : StyledAnchor;
  const props = {
    ...(to && { to, exact }),
    ...(onClick && { onClick }),
    caption,
  };
  return (
    <Container menuMode={menuMode} hasCaption={!!caption}>
      <Component {...props}>
        {icon}
        {menuMode === "expanded" &&
          (caption ? (
            <Content>
              {label}
              <Body2>{caption}</Body2>
            </Content>
          ) : (
            label
          ))}
      </Component>
    </Container>
  );
};

interface ContainerProps {
  menuMode: MenuMode;
  hasCaption: boolean;
}

const Container = styled.div<ContainerProps>`
  svg {
    ${({ menuMode }) =>
      menuMode === "expanded" &&
      css`
        margin-right: 20px;
      `}

    ${({ menuMode }) =>
      menuMode === "collapsed" &&
      css`
        margin-left: 5px;
      `}
    ${({ hasCaption, menuMode }) =>
      hasCaption &&
      ((menuMode === "expanded" && "margin-top: -14px;") ||
        (menuMode === "collapsed" && "margin-top: 0px;"))}
  }
`;

const linkStyle = css`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 0 0 30px;
  color: inherit;
  font-weight: 500;
  line-height: 24px;
  height: 44px;
  font-family: Poppins;
  font-size: 16px;
  letter-spacing: 0;
  text-decoration: none;
  &.active {
    color: #1b0076;
    background-color: #e8e5f1;
    border-radius: 0 24px 24px 0;
    font-weight: 600;
  }
`;
const StyledAnchor = styled.a`
  ${linkStyle}
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  ${Body2} {
    margin-top: -3px;
    color: #999999;
  }
`;
