import { FlexColumn } from "Components/Flex";
import { Body1, H2 } from "melodies-source/Text";
import { TipCard } from "./TipCard";
import styled from "styled-components";

export const BlankState = () => {
  const assetsPath =
    process.env.REACT_APP_ASSETS_PATH || "https://set.live/assets";
  return (
    <Wrapper>
      <Header>
        <H2>
          Reporting data will appear here when fans begin visiting your page.
        </H2>
        <Body1>
          Meanwhile, check out these helpful resources to make the most of your
          page:
        </Body1>
      </Header>
      <GridRow>
        <TipCard
          image={`${assetsPath}/artist-portal/set-page/boost-traffic.png`}
          description="Tips to boost traffic to your page"
          actionLabel="Learn more"
          onClick={() => {}}
        />
        <TipCard
          image={`${assetsPath}/artist-portal/set-page/take-over.png`}
          description="How take-over promos amplify your music"
          actionLabel="Get Started"
          onClick={() => {}}
        />
        <TipCard
          image={`${assetsPath}/artist-portal/set-page/page-tips.png`}
          description="Our top tips for a better page"
          actionLabel="Read Tips"
          onClick={() => {}}
        />
        <TipCard
          image={`${assetsPath}/artist-portal/set-page/integrations.png`}
          description="Integrate with SET.Live and SET.Fan"
          actionLabel="Learn How"
          onClick={() => {}}
        />
      </GridRow>
    </Wrapper>
  );
};

const Header = styled(FlexColumn)`
  gap: 16px;
  text-align: center;
  width: 65%;

  ${({ theme }) => theme.mediaQueries.mobile} {
    align-items: center;
    gap: 8px;
    width: 100%;
    max-width: 320px;
    ${H2} {
      font-size: 16px;
      line-height: 24px;
    }
    ${Body1} {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

const Wrapper = styled(FlexColumn)`
  align-items: center;
  justify-content: center;
  margin: 92px auto 120px;
  gap: 44px;
  flex-grow: 1;

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin: 12px auto 24px;
    gap: 44px;
  }
`;

const GridRow = styled.div`
  display: grid;
  grid-template: auto / repeat(4, minmax(180px, 220px));
  gap: 24px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    gap: 20px;
    grid-template: auto / 1fr 1fr;
  }
`;
