import { aarp as aarpConfig } from "@max/thirdparty/companies/aarp";
import { GtbAffiliationMap } from "@max/common/dist/thirdparty/gtb";
import { UserMenuOptions } from "../gtb/Components/UserMenuOptions";
import { GtbCustomApp } from "../gtb/types";
import { gtb } from "../gtb";

export const aarp: GtbCustomApp = {
  ...aarpConfig,
  routes: gtb.routes,
  UserMenuOptions,
  userRoles: JSON.parse(
    JSON.stringify(GtbAffiliationMap).replaceAll("GTB", "AARP"),
  ),
};
