import styled from "styled-components";
import { FlexColumn } from "Components/Flex";
import { Card as MelodiesCard } from "melodies-source/Card";
import { H3, Body2, H1, Body1 } from "melodies-source/Text";
import { ReactComponent as IconPulse } from "assets/svg/pulse.svg";

export const ReportCard = styled(MelodiesCard)`
  display: flex;
  flex-direction: column;
  color: #333;
  ${H3} {
    font-weight: 600;
    text-align: center;
  }

  ${({ theme }) => theme.mediaQueries.desktop} {
    border-radius: 20px;
  }
`;

export const ReportMetricCard = styled(ReportCard)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 32px;
  padding-bottom: 44px;

  & > ${FlexColumn} {
    width: 100%;
  }
  & > ${Body2} {
    color: #666666;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 24px;
    padding-bottom: 32px;
  }
`;

export const FlatCard = styled(ReportCard)`
  background-color: #f2f5f7;
  border: none;
  gap: 24px;
  padding: 32px;
  padding-bottom: 44px;

  ${H1} {
    color: #333333;
    line-height: 44px;
  }
  ${Body1} {
    color: #666666;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    align-items: center;
    gap: 16px;
    padding: 24px;
    padding-bottom: 32px;

    ${H1} {
      font-size: 20px;
      line-height: 28px;
    }
  }
`;

export const NoData = (props) => {
  return (
    <NoDataContainer {...props}>
      <IconPulse />
      <H3>Insufficient Data</H3>
      <Body1>This section will populate as more fans visit your site</Body1>
    </NoDataContainer>
  );
};

const NoDataContainer = styled(FlexColumn)`
  align-items: center;
  justify-content: center;
  color: #999;
  text-align: center;
  gap: 12px;
  flex-grow: 1;

  ${H3} {
    font-weight: 500;
  }
  ${Body1} {
    margin-bottom: 56px;
  }
  svg {
    color: #d1cce4;
    width: 44px;
    height: 44px;
  }
`;
