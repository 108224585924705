import { SelectMenu } from "melodies-source/SelectMenu";
import { SvgCheck } from "melodies-source/Svgs/Check";
import { SvgDropdown } from "melodies-source/Svgs/Dropdown";
import {
  Body1,
  Body2,
  Caption,
  Label,
  Selected,
  Subtitle2,
} from "melodies-source/Text";
import { Fragment, useState } from "react";
import styled, { css } from "styled-components";

export type SelectOption = {
  label: React.ReactNode;
  caption?: React.ReactNode;
  value?: string;
  options?: Omit<SelectOption, "options">[];
  disabled?: boolean;
};

interface SelectProps {
  label?: string;
  onChange: (v: string) => void;
  value: string;
  options: SelectOption[];
  placeholder?: string;
  style?: React.CSSProperties;
  helperText?: string;
  hasError?: boolean;
}

export const Select = ({
  label,
  onChange,
  value,
  options,
  placeholder,
  helperText,
  hasError,
  ...props
}: SelectProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const selected: SelectOption = options
    ?.flatMap((curr) => curr.options || [curr])
    ?.find((option) => option.value === value);

  return (
    <SelectWrapper {...props}>
      {label && <Label>{label}</Label>}
      <SelectedValue
        onClick={() => {
          setIsMenuOpen(true);
        }}
        isOpen={isMenuOpen}
      >
        <TextContainer>
          {selected ? (
            <Selected style={{ fontWeight: 500 }}>{selected?.label}</Selected>
          ) : (
            <Body2>{placeholder}</Body2>
          )}
        </TextContainer>
        <Dropdown />
      </SelectedValue>
      <SelectMenu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
        {options.map((option, idx) =>
          !option.options ? (
            <ListItemComponent
              label={option.label}
              disabled={option.disabled}
              caption={option.caption}
              key={`select-option-${idx}`}
              selected={selected?.value === option.value}
              onClick={() => {
                if (option.disabled) {
                  return;
                }

                setIsMenuOpen(false);
                onChange(option.value);
              }}
            />
          ) : (
            <Fragment key={`select-option-${idx}`}>
              <SelectListSubtitle>
                <Subtitle2>{option.label}</Subtitle2>
              </SelectListSubtitle>
              {option.options.map((o, nIdx) => (
                <ListItemComponent
                  {...o}
                  key={`nested-select-option-${idx}-${nIdx}`}
                  selected={selected?.value === o.value}
                  onClick={() => {
                    setIsMenuOpen(false);
                    onChange(o.value);
                  }}
                />
              ))}
            </Fragment>
          ),
        )}
      </SelectMenu>
      {helperText && (
        <Caption
          style={{ marginTop: 4, color: hasError ? "#FF0000" : "#ffffff" }}
        >
          {helperText}
        </Caption>
      )}
    </SelectWrapper>
  );
};

type ListItemComponentProps = Pick<
  SelectOption,
  "label" | "caption" | "disabled"
> & {
  selected?: boolean;
  onClick?: VoidFunction;
};

const ListItemComponent = ({
  selected,
  label,
  caption,
  disabled,
  ...props
}: ListItemComponentProps) => {
  return (
    <SelectListItem {...props} $disabled={disabled}>
      <ListItemRow>
        {selected && (
          <CheckIconWrapper isSelected={selected}>
            <SvgCheck />
          </CheckIconWrapper>
        )}
        <Body1>{label}</Body1>
      </ListItemRow>
      {caption && <Caption>{caption}</Caption>}
    </SelectListItem>
  );
};

const TextContainer = styled.span`
  flex-grow: 1;
  overflow: hidden;
  ${Selected} {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
type SelectedValueProps = {
  hasError?: boolean;
  isOpen?: boolean;
};
const SelectedValue = styled.div<SelectedValueProps>`
  display: flex;
  width: 100%;
  height: 44px;
  background: #ffffff;
  border-radius: 6px;
  border: solid 1px ${(p) => p.theme.colors.black20};
  padding: 11px 12px;
  user-select: none;
  cursor: pointer;
  align-items: center;
  ${Body2} {
    color: #999999;
  }
  ${(p) =>
    p.hasError &&
    css`
      border: solid 1px ${(p) => p.theme.colors.error};
    `};
  ${(p) =>
    p.isOpen &&
    css`
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24);
    `};
`;

const ListItemRow = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  ${Body1} {
    color: ${(p) => p.theme.colors.black80};
    line-height: 20px;
    font-weight: 500;
  }
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 44px;
  padding: 8px 12px 8px 44px;
  position: relative;
  min-width: 180px;
  user-select: none;
`;

const SelectListItem = styled(ListItem)<{ $disabled?: boolean }>`
  :hover {
    cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
    background: ${(p) => p.theme.colors.gray2};
  }
  & + & {
    border-top: solid 1px ${(p) => p.theme.colors.gray3};
  }
  ${({ $disabled }) =>
    $disabled &&
    `
      ${Body1} {
        color: #999;
      }
      path {
        color: #999;
      }
    `};
`;

const SelectListSubtitle = styled(ListItem)`
  justify-content: flex-end;
  padding-left: 12px;
  ${Subtitle2} {
    color: #666666;
  }
  ${SelectListItem} + & {
    border-top: solid 1px ${(p) => p.theme.colors.gray3};
  }
  &:first-child {
    min-height: 36px;
  }
`;

type CheckIconProps = {
  isSelected: boolean;
};
const CheckIconWrapper = styled.div<CheckIconProps>`
  display: flex;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 12px;
  ${(p) =>
    p.isSelected
      ? css`
          color: ${(p) => p.theme.colors.navy};
        `
      : css`
          opacity: 0;
        `}
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  ${Label} {
    margin-bottom: 4px;
  }
`;

const Dropdown = styled(SvgDropdown)`
  color: ${(p) => p.theme.colors.black60};
`;
