import { FlexColumn, FlexRow } from "Components/Flex";
import { SvgRight } from "melodies-source/Svgs/Right";
import { Body1, Subtitle1 } from "melodies-source/Text";
import styled from "styled-components";

interface TipCardProps extends React.HTMLAttributes<HTMLDivElement> {
  image: string;
  description: string;
  actionLabel: string;
}

export const TipCard = ({
  image,
  description,
  actionLabel,
  onClick,
  ...props
}: TipCardProps) => {
  return (
    <Container {...props}>
      <Image src={image} />
      <Content>
        <FlexRow style={{ flexGrow: 1 }}>
          <Body1>{description}</Body1>
        </FlexRow>
        <Button xSpaceBetween onClick={onClick}>
          <Subtitle1>{actionLabel}</Subtitle1>
          <SvgRight />
        </Button>
      </Content>
    </Container>
  );
};

const Button = styled(FlexRow)`
  color: #8017f6;

  ${({ theme }) => theme.mediaQueries.mobile} {
    ${Subtitle1} {
      font-size: 12px;
      line-height: 18px;
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

const Content = styled(FlexColumn)`
  padding: 17px 15px;
  flex-grow: 1;
  gap: 16px;
  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 14px 12px;
    ${Body1} {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  aspect-ratio: 1.87;
  object-fit: cover;
`;

const Container = styled(FlexColumn)`
  border: 1px solid #dcdfe0;
  border-radius: 12px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;
