import { createContext, useContext, useEffect, useRef, useState } from "react";
import {
  useCollection,
  useCollectionData,
  useDocument,
} from "react-firebase-hooks/firestore";
import firebase from "firebase/compat/app";
import { useRouteMatch, useHistory } from "react-router";
import * as Interface from "@musicaudienceexchange/shoutout-interface";
import { useUser } from "auth";
import { DateTime } from "luxon";
import { Data } from "react-firebase-hooks/firestore/dist/firestore/types";
import { useTimeContext } from "Components";
import {
  getFirestore,
  doc,
  collection,
  where,
  orderBy,
  query,
  DocumentSnapshot,
  DocumentData,
  QuerySnapshot,
  setDoc,
  DocumentReference,
} from "firebase/firestore";
import { useLocation } from "react-router-dom";
import { artist } from "@max/common/dist/functions";
import { UserActionDoc, logUserAction } from "@max/common/dist/actions";
import { ArtistGroup } from "@max/common/src/artists";

interface ArtistProviderData {
  id: string;
  name: string;
  assets: {
    icon?: { path: string };
    headerSmall?: { path: string };
    headerLarge?: { path: string };
  };
  isAdmin: boolean;
  isGlobalAdmin: boolean;
  getArtistGroup: () => Promise<null>;
  artistGroup: Interface.functions.artists.GetArtistGroupResponse;
  artistGroupDoc: DocumentSnapshot<DocumentData>;
  privateData: DocumentSnapshot<DocumentData>;
  privateSettings: DocumentSnapshot<DocumentData>;
  privateProfileData: DocumentSnapshot<DocumentData>;
  meetGreets: QuerySnapshot<DocumentData>;
  shoutouts: Data<DocumentData, "", "">[];
  shoutoutEvents: Data<DocumentData, "", "">[];
  setlives: Data<DocumentData, "", "">[];
  hideWelcome: (save?: boolean) => void;
  showWelcome: boolean;
  logAction: (
    action: UserActionDoc["action"],
    metadata?: UserActionDoc["metadata"],
  ) => Promise<{ success: boolean }>;
  spotifyArtistId?: string;
}

export const REDIRECT_PATHS = ["/help-center", "/settings"];

export const ArtistContext = createContext(undefined);
export const ArtistConsumer = ArtistContext.Consumer;
export const ArtistProvider = ({ children }) => {
  const history = useHistory();
  const { time } = useTimeContext();
  const { user } = useUser();
  const updating = useRef(false);
  const { uid } = user;
  const [artistGroup, setArtistGroup] = useState(undefined) as [
    Interface.functions.artists.GetArtistGroupResponse,
    Function,
  ];
  const [isGlobalAdmin, setIsGlobalAdmin] = useState(false);
  const [hiddenWelcome, setHiddenWelcome] = useState([]);
  const match = useRouteMatch(`/:artistId`) as {
    params: { artistId: string };
  };
  const location = useLocation();

  let artistId = match?.params?.artistId;

  if (REDIRECT_PATHS.includes(location.pathname)) {
    artistId = undefined;
  }

  const [artistGroupDoc, loading] = useDocument(
    doc(
      getFirestore(),
      `artist_groups/${artistId}`,
    ) as DocumentReference<ArtistGroup>,
  );
  const [privateData, pdl] = useDocument(
    doc(getFirestore(), `artist_groups/${artistId}/artist_group_private/data`),
  );
  const [privateProfileData] = useDocument(
    doc(
      getFirestore(),
      `artist_groups/${artistId}/artist_group_private/profile`,
    ),
  );
  const [privateSettings] = useDocument(
    doc(
      getFirestore(),
      `artist_groups/${artistId}/artist_group_private/settings`,
    ),
  );

  const [meetGreets] = useCollection(
    query(
      collection(getFirestore(), "meet_greet"),
      where("artistGroupId", "==", artistId || "none"),
      orderBy("endDate", "desc"),
    ),
  );

  const [shoutouts] = useCollectionData(
    query(
      collection(getFirestore(), "shoutout_requests"),
      where("shoutout.artistGroupId", "==", artistId || "none"),
      where("status", "in", ["received", "viewed"]),
    ),
  );

  const [shoutoutEvents] = useCollectionData(
    query(
      collection(getFirestore(), "shoutouts"),
      where("artistGroupId", "==", artistId || "none"),
      orderBy("createdAt", "desc"),
    ),
  );

  const [setlives] = useCollectionData(
    query(
      collection(getFirestore(), "events"),
      where("artistIds", "array-contains", artistId || "none"),
    ),
    { idField: "id" },
  );

  const getArtistGroup = async () => {
    try {
      const res = await artist.members({
        artistGroupId: artistId,
      });

      setArtistGroup(res.data);
    } catch (error) {
      // Redirect user to artist select if permission errors are thrown.
      history.replace("/");
    }
  };

  useEffect(() => {
    if (artistId) {
      getArtistGroup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artistId]);

  useEffect(() => {
    if (
      !updating.current &&
      privateData?.data()?.lastUpdate &&
      DateTime.fromMillis(privateData?.data()?.lastUpdate?.toMillis()).plus({
        minutes: 10,
      }) < time
    ) {
      updating.current = true;
      firebase
        .functions()
        .httpsCallable(`artist-fanStats`)({ id: artistId })
        .then(() => {
          updating.current = false;
        })
        .catch((err) => console.error(err));
    }
  }, [time, artistId, privateData]);

  useEffect(() => {
    (async () => {
      if (user) {
        try {
          const res = await user.getIdTokenResult();
          setIsGlobalAdmin(!!res?.claims?.admin);
        } catch (err) {
          console.error(user, err);
        }
      }
    })();
  }, [user]);

  const isAdmin = artistGroup?.members
    .find((m) => m.uid === uid)
    ?.roles?.includes("admin");

  const hideWelcome = (save?: boolean) => {
    setHiddenWelcome((curr) => [...curr, artistId]);
    if (save) {
      setDoc(privateData.ref, { seenWelcome: true }, { merge: true });
    }
  };

  const showWelcome = !(
    hiddenWelcome.includes(artistId) ||
    privateData?.data()?.seenWelcome ||
    pdl
  );

  const logAction = async (
    action: UserActionDoc["action"],
    metadata?: UserActionDoc["metadata"],
  ) => await logUserAction({ action, artistGroupId: artistId, metadata, uid });

  if (loading || (artistId && !artistGroupDoc?.data())) {
    return null;
  }

  return (
    <ArtistContext.Provider
      value={
        {
          ...artistGroupDoc.data(),
          id: artistId,
          isAdmin: isGlobalAdmin || isAdmin,
          isGlobalAdmin,
          getArtistGroup,
          artistGroup,
          artistGroupDoc,
          privateData,
          privateSettings,
          privateProfileData,
          meetGreets,
          shoutouts,
          shoutoutEvents,
          setlives,
          hideWelcome,
          showWelcome,
          logAction,
        } as ArtistProviderData
      }
    >
      {children}
    </ArtistContext.Provider>
  );
};
export const useArtistContext = (): ArtistProviderData =>
  useContext(ArtistContext);
