import styled from "styled-components";
import { useArtistContext, useMenuContext } from "Components";
import { useParams } from "react-router-dom";
import { LinkItem, Link } from "./Components/Link";
import { SvgSetList } from "melodies-source/Svgs/SetList";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { ReactComponent as ClipboardIcon } from "assets/svg/clipboard-fill.svg";
import { SvgClipboard } from "melodies-source/Svgs/Clipboard";
import { ReactComponent as SvgSetBio } from "assets/svg/SET-Bio2.svg";
import { ReactComponent as SvgSetLive } from "assets/svg/SET-Live.svg";

export const AppLinks = () => {
  const context = useArtistContext();
  const setlive = process.env.REACT_APP_SL_URL;
  const sts = context?.privateData?.data()?.settheset;
  const { artistId } = useParams<{ artistId: string }>();
  const { menuMode } = useMenuContext();
  const { customApp } = useCustomAppContext();

  const links: LinkItem[] = [
    {
      icon: customApp ? (
        customApp.menu.sections.engagement.items.live.icon || <ClipboardIcon />
      ) : (
        <SvgSetLive />
      ),
      label: customApp?.menu.sections.engagement.items.live.label || "SET.Live",
      caption: "For Live Capture",
      to: `/${artistId}/set-live`,
    },
    ...(sts && !customApp
      ? [
          {
            icon: <SvgSetList />,
            label: "Livestream",
            onClick: () => window.open(setlive, "_blank"),
          },
        ]
      : []),
    ...(!customApp
      ? [
          {
            icon: <SvgClipboard />,
            label: "SET.Fan",
            caption: "For Digital Capture",
            to: `/${artistId}/set-fan/surveys`,
          },
        ]
      : []),
    ...(!customApp
      ? [
          {
            icon: <SvgSetBio />,
            label: "SET.Bio",
            caption: "For Link-In-Bio",
            to: `/${artistId}/set-bio`,
          },
        ]
      : []),
  ];
  return (
    <Container>
      {menuMode === "expanded" && (
        <Header>
          {customApp?.menu.sections.engagement.header || "Fan Engagement Tools"}
        </Header>
      )}
      {links.map((link) => (
        <Link key={link.label} {...link} />
      ))}
    </Container>
  );
};

const Header = styled.h1`
  color: var(--sidebar-section-header-color);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  padding: 0 0 12px 20px;
`;

const Container = styled.div`
  box-shadow: inset 0 1px 0 0 var(--box-shadow-color),
    inset 0 -1px 0 0 var(--box-shadow-color);
  padding: 16px 20px 16px 0;
  display: flex;
  flex-direction: column;
  gap: 7px;
`;
