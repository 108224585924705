import styled, { FlattenSimpleInterpolation, css } from "styled-components";
import { useEffect, useRef, useState } from "react";
import { SvgAccordionOpen } from "melodies-source/Svgs/AccordionOpen";
import { AnimationToggle } from "Components/Animation";
import { useClickOutside } from "hooks/useClickOutside";
import { Label, LinkText } from "melodies-source/Text";

const DROPDOWN_WIDTH = 178;

const DropdownMenu = <T extends string>({
  className,
  disableAnimation,
  forceSide,
  icon,
  labelAs,
  menuLabel,
  onClick,
  options,
  value,
  styles,
}: {
  className?: string;
  disableAnimation?: boolean;
  forceSide?: "left" | "right";
  icon?: JSX.Element;
  labelAs?: string | React.ComponentType<any>;
  menuLabel?: string;
  onClick: (val: T) => void;
  options: {
    label: string;
    value: T;
    icon?: JSX.Element;
    labelStyles?: FlattenSimpleInterpolation;
  }[];
  value?: T;
  styles?: {
    label?: FlattenSimpleInterpolation;
    option?: FlattenSimpleInterpolation;
    arrow?: FlattenSimpleInterpolation;
  };
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuSide, setMenuSide] = useState<"left" | "right">(
    forceSide ?? "right",
  );

  const containerRef = useRef<HTMLDivElement>(null);

  useClickOutside(containerRef, () => setMenuOpen(false));

  useEffect(() => {
    if (containerRef?.current) {
      const xPos = containerRef.current.getBoundingClientRect().x;
      const toRightSide = window.innerWidth - xPos;

      if (toRightSide < DROPDOWN_WIDTH) {
        setMenuSide("left");
      } else {
        setMenuSide("right");
      }
    }
  }, [containerRef, forceSide]);

  return (
    <Container ref={containerRef} className={className}>
      <MenuContainer onClick={() => setMenuOpen((menuOpen) => !menuOpen)}>
        {/* if we have a menu label OR a selected value, show a label
         * if you just want a popout menu, pass no value */}
        {(menuLabel || value) && (
          <MenuText as={labelAs} customStyle={styles?.label}>
            {menuLabel ?? options.find((opt) => opt.value === value)?.label}
          </MenuText>
        )}
        {!!icon ? (
          <ToggleAnimation toggle={menuOpen} disable={disableAnimation}>
            {icon}
          </ToggleAnimation>
        ) : (
          <ToggleAnimation toggle={menuOpen} disable={disableAnimation}>
            <MenuCaret menuOpen={menuOpen} customStyle={styles?.arrow} />
          </ToggleAnimation>
        )}
      </MenuContainer>
      <DropdownContainer
        menuOpen={menuOpen}
        numOptions={options.length}
        side={menuSide}
      >
        {options.map((option) => (
          <DropdownOption
            onClick={() => {
              setMenuOpen(false);
              onClick(option.value);
            }}
            selected={option.value === value}
            key={option.label}
            customStyle={styles?.label}
          >
            {option.icon}
            <LabelText customStyle={option.labelStyles}>
              {option.label}
            </LabelText>
          </DropdownOption>
        ))}
      </DropdownContainer>
    </Container>
  );
};

export default DropdownMenu;

const ToggleAnimation = ({
  toggle,
  disable,
  children,
}: {
  toggle: boolean;
  disable?: boolean;
  children: JSX.Element;
}) => {
  return disable ? (
    <div style={{ display: "inherit" }}>{children}</div>
  ) : (
    <AnimationToggle
      toggle={toggle}
      duration={300}
      states={[{ transform: "scaleY(-1)" }, { transform: "scaleY(1)" }]}
    >
      {children}
    </AnimationToggle>
  );
};

const Container = styled.div`
  position: relative;
`;

interface CustomStyleProps {
  customStyle: FlattenSimpleInterpolation;
}

interface MenuCaretProps extends CustomStyleProps {
  menuOpen: boolean;
}
const MenuCaret = styled(SvgAccordionOpen)<MenuCaretProps>`
  width: 30px;
  height: 30px;

  ${({ customStyle }) => customStyle}
`;
type DropdownContainerProps = {
  menuOpen: boolean;
  numOptions: number;
  side: "left" | "right";
};
const DropdownContainer = styled.div<DropdownContainerProps>`
  position: absolute;
  width: ${DROPDOWN_WIDTH}px;
  cursor: pointer;
  z-index: 10;

  overflow-y: hidden;
  height: ${({ menuOpen, numOptions }) =>
    menuOpen ? `${numOptions * 44}px` : 0};
  opacity: ${({ menuOpen }) => (menuOpen ? 1 : 0)};
  transition: all 0.3s;

  background: var(--component-background-color);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
  border-radius: 6px;

  top: 40px;
  ${({ side }) => (side === "right" ? "left: 0px" : "right: 0px")};
`;

interface DropdownOptionProps extends CustomStyleProps {
  selected: boolean;
}

const DropdownOption = styled.div<DropdownOptionProps>`
  height: 44px;
  display: flex;
  align-items: center;
  padding-left: 20px;

  ${({ selected }) =>
    selected &&
    css`
      background: var(--selected-color);
    `}

  :hover {
    background: var(--hover-background-color);
  }

  ${({ customStyle }) => customStyle}
`;

const LabelText = styled(Label)<CustomStyleProps>`
  color: var(--text-color);
`;

const MenuContainer = styled.menu`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const MenuText = styled(LinkText)<CustomStyleProps>`
  font-weight: 500;
  color: var(--text-color);
  margin-right: 5px;

  ${({ customStyle }) => customStyle}
`;
