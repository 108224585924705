import {
  Bar,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  Cell,
  Legend,
} from "recharts";
import styled from "styled-components";
import { CustomTooltip } from "./CustomTooltip";
import { TickFormatter } from "recharts/types/cartesian/CartesianAxis";
import { BaseAxisProps } from "recharts/types/util/types";
import {
  NameType,
  Payload,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { Payload as LegendPayload } from "recharts/types/component/DefaultLegendContent";
import { H4 } from "melodies-source/Text";
import { FlexRow } from "Components/Flex";
import { ReactComponent as ChartLineSymbol } from "assets/svg/chart-legend-line.svg";

export type KeyedValue = {
  [key: string]: number | string;
  id: string;
  fill?: string;
};

const ChartTypes = ["bar", "line"] as const;
type ChartType = (typeof ChartTypes)[number];

export type ChartDefinition = {
  dataKey: string;
  chartType: ChartType;
  label?: string;
  color?: string;
  fill?: string;
  formatValue?: (v: Payload<ValueType, NameType>) => string;
};

interface MixedChartProps extends React.HTMLAttributes<HTMLDivElement> {
  data?: KeyedValue[];
  defs?: ChartDefinition[];
  indexBy?: string;
  tickFormatter?: TickFormatter;
  layout?: "horizontal" | "vertical";
}

export const MixedChart = ({
  data,
  indexBy,
  defs,
  tickFormatter,
  layout = "horizontal",
  ...props
}: MixedChartProps) => {
  const bars = defs?.filter(({ chartType }) => chartType === "bar");
  const lines = defs?.filter(({ chartType }) => chartType === "line");

  const settings = {
    horizontal: {
      fontSize: "14px",
      margin: {
        top: 5,
        right: 5,
        bottom: 5,
        left: 5,
      },
      xAxis: {
        dataKey: indexBy,
        interval: 0,
        minTickGap: 0,
        tickMargin: 15,
        tickFormatter,
        type: "category" as BaseAxisProps["type"],
      },
      yAxis: {
        tickMargin: 20,
        type: "number" as BaseAxisProps["type"],
      },
    },
    vertical: {
      margin: {
        top: 0,
        right: 5,
        bottom: 5,
        left: 0,
      },
      fontSize: "11px",
      xAxis: {
        tickMargin: 20,
        type: "number" as BaseAxisProps["type"],
      },
      yAxis: {
        dataKey: indexBy,
        interval: 0,
        minTickGap: 0,
        tickMargin: 10,
        tickFormatter,
        type: "category" as BaseAxisProps["type"],
      },
    },
  };

  const activeSettings = settings[layout];

  const renderLegend = ({ payload }: { payload: LegendPayload[] }) => {
    const getDefinition = (key: string) =>
      defs.find(({ dataKey }) => dataKey === key);

    return (
      <LegendContainer>
        {payload.map((entry, index) => {
          const def = getDefinition(entry.value);
          const fillShape =
            def.chartType === "line" ? (
              <ChartLineSymbol
                style={{ color: entry.color, width: 18, height: 18 }}
              />
            ) : (
              <Color style={{ background: entry.color || def.color }} />
            );
          return (
            <FlexRow yCenter gap="8px">
              {fillShape}
              <H4 key={`legend-item-${index}`}>{def?.label || entry.value}</H4>
            </FlexRow>
          );
        })}
      </LegendContainer>
    );
  };

  return (
    <Container {...props}>
      <ResponsiveContainer>
        <ComposedChart
          data={data}
          barCategoryGap="15%"
          layout={layout}
          margin={activeSettings.margin}
        >
          <XAxis
            axisLine={false}
            tickLine={false}
            style={{ fill: "#ccc", fontSize: activeSettings.fontSize }}
            allowDataOverflow
            {...activeSettings.xAxis}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            style={{ fill: "#ccc", fontSize: activeSettings.fontSize }}
            allowDataOverflow
            {...activeSettings.yAxis}
          />
          <Tooltip
            isAnimationActive={false}
            cursor={false}
            content={<CustomTooltip defs={defs} />}
          />
          <Legend align="center" content={renderLegend} />
          {bars?.map((barData, barIdx) => (
            <Bar
              key={`bar-${barIdx}`}
              dataKey={barData.dataKey}
              activeBar={{ opacity: 0.5 }}
              minPointSize={9}
            >
              {data?.map((entry, index) => (
                <Cell
                  key={`bar-cell-${index}`}
                  fill={entry.fill || barData.fill || "#0095EF"}
                />
              ))}
            </Bar>
          ))}
          {lines?.map((lineData, index) => (
            <Line
              key={`line-${index}`}
              activeDot={{ strokeWidth: 3 }}
              dataKey={lineData.dataKey}
              dot={{
                fill: lineData.fill || "#1B0076",
                strokeWidth: 5,
              }}
              stroke={lineData.fill || "#1B0076"}
              strokeWidth={4}
              type="linear"
              animationDuration={750}
              animationBegin={200}
            />
          ))}
        </ComposedChart>
      </ResponsiveContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 400px;

  svg.recharts-surface {
    overflow: visible;
  }
`;

const LegendContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-top: 32px;

  ${H4} {
    color: #666666;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    gap: 16px;
    margin-top: 20px;
    ${H4} {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

const Color = styled(FlexRow)`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  flex-shrink: 0;

  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 12px;
    height: 12px;
  }
`;
