export const addHttpsProtocolToUrl = (url: string) =>
  url.replace(/^(http(s)?:\/\/)?/i, () => "https://");

export const validVideoUrl = async (value: string) => {
  const response = await fetch(`https://noembed.com/embed?url=${value}`);
  const json = await response.json();

  if ("error" in json) {
    return false;
  }

  return ["Vimeo", "YouTube"].includes(json.provider_name);
};

export const isSocialHandle = (value?: string) => value?.startsWith("@");

export const hasValidServiceDomain = (
  serviceUrl: string,
  service: { domains: string[] },
) => {
  if (!serviceUrl) {
    return false;
  }

  let url: URL = undefined;
  try {
    url = new URL(addHttpsProtocolToUrl(serviceUrl));
    // ignore invalid urls as the value is either a partial url or already run through a url validator
  } catch (err) {}
  let validDomain = false;
  for (const domain of service.domains) {
    if (url?.hostname.endsWith(domain)) {
      validDomain = true;
    }
  }
  if (!validDomain) {
    return false;
  }

  return true;
};
