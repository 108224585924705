import { useDesktopMediaQuery } from "hooks";
import styled, { css } from "styled-components";

const DashboardBG = () => {
  const isDesktop = useDesktopMediaQuery();

  const height = isDesktop ? "300" : "220";

  return (
    <>
      <svg
        width="100%"
        height={height}
        viewBox="0 0 1020 299"
        fill="none"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ position: "absolute" }}
      >
        <g clipPath="url(#clip0_1_2994)">
          <rect
            width="100%"
            height={height}
            fill="url(#paint0_linear_1_2994)"
          />
          <mask
            id="mask0_1_2994"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="100%"
            height={height}
          >
            <rect width="100%" height={height} fill="white" />
          </mask>
          <g mask="url(#mask0_1_2994)">
            <g opacity="0.4">
              <path
                d="M460.528 -414.641H255.369C242.106 -414.614 229.776 -405.507 222.697 -390.508L-33.9336 152.451L222.647 695.476C229.726 710.476 242.056 719.583 255.319 719.609H460.453L192.487 152.484L460.528 -414.641Z"
                fill="url(#paint1_linear_1_2994)"
              />
              <path
                d="M-302 -414.641L-33.9342 152.619L-302 719.744H-96.8665C-83.6032 719.717 -71.2735 710.61 -64.194 695.611L192.462 152.619L-64.0683 -390.373C-71.1483 -405.44 -83.5101 -414.601 -96.8163 -414.641H-302Z"
                fill="url(#paint2_linear_1_2994)"
              />
            </g>
          </g>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_1_2994"
            x1="763.169"
            y1="-117.495"
            x2="273.96"
            y2="698.907"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F38E9E" />
            <stop offset="0.17" stopColor="#E38CA2" />
            <stop offset="0.31" stopColor="#C487AB" />
            <stop offset="0.47" stopColor="#AC84B2" />
            <stop offset="0.62" stopColor="#9A81B6" />
            <stop offset="0.829983" stopColor="#9080B9" />
            <stop offset="1" stopColor="#8D7FBA" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1_2994"
            x1="402.075"
            y1="-1078.33"
            x2="-576.454"
            y2="-869.647"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.11" stopColor="#E71E3D" stopOpacity="0.5" />
            <stop offset="0.17" stopColor="#C81A46" stopOpacity="0.5" />
            <stop offset="0.31" stopColor="#8A1057" stopOpacity="0.5" />
            <stop offset="0.47" stopColor="#590965" stopOpacity="0.5" />
            <stop offset="0.62" stopColor="#36046E" stopOpacity="0.5" />
            <stop offset="0.8" stopColor="#220174" stopOpacity="0.5" />
            <stop offset="1" stopColor="#1B0076" stopOpacity="0.5" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1_2994"
            x1="184.729"
            y1="-1262.96"
            x2="-727.056"
            y2="-1107.56"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E71E3D" />
            <stop offset="0.07" stopColor="#C61946" />
            <stop offset="0.19" stopColor="#991353" />
            <stop offset="0.31" stopColor="#720D5E" />
            <stop offset="0.43" stopColor="#530866" />
            <stop offset="0.55" stopColor="#3A056D" />
            <stop offset="0.69" stopColor="#290272" />
            <stop offset="0.83" stopColor="#1E0075" />
            <stop offset="1" stopColor="#1B0076" />
          </linearGradient>
          <clipPath id="clip0_1_2994">
            <rect width="100%" height={height} fill="white" />
          </clipPath>
        </defs>
      </svg>
      <Overlay />
    </>
  );
};

const Overlay = styled.div(({ theme }) => {
  if (!theme.custom) return null;
  const { background } = theme.custom.layout.content.backgroundHeader;
  return css`
    position: absolute;
    width: 100%;
    height: 300px;
    background: ${background};
  `;
});

export default DashboardBG;
