import { H3 } from "melodies-source/Text";
import { FlatCard, NoData } from ".";
import { PieChart } from "Components/PieChart";
import { Metric } from "../aggregate";

interface ClicksBySectionProps {
  metrics?: Metric[];
}

export const ClicksBySection = ({
  metrics,
  ...props
}: ClicksBySectionProps) => {
  return (
    <FlatCard {...props}>
      <H3>Clicks by Section</H3>
      {metrics?.length ? (
        <PieChart
          dataKey="clicks"
          nameKey="label"
          data={metrics}
          style={{ textTransform: "capitalize" }}
        />
      ) : (
        <NoData />
      )}
    </FlatCard>
  );
};
