"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.aarp = void 0;
const colors = {
    main: "#EC1300",
    secondary: "#79201A",
    tertiary: "#79201A",
    background: "white",
    text: "#333333",
    headerText: "#343a40",
    secondaryText: "#999999",
    primaryButtonText: "white",
    primaryButtonHover: "#a00d00",
    componentBackground: "#FFFFFF",
    border: "#ced4da",
    inputBorder: "#ced4da",
    hoverBackground: "#fde7e5",
    boxShadow: "rgba(0,0,0,0.1)",
    selected: "#51cf66",
    contentBackground: "#F9F8F6",
    disabled: "#565656",
    spinner: "#EC1300",
    liveEventCardBackground: "#ffc4bf",
};
exports.aarp = {
    app: { name: "SPONSORSHIP PROGRAMS" },
    company: {
        name: "AARP",
        logo: {
            url: "https://cdn.aarp.net/content/dam/aarp/graphics/aarp_logos/120x30-aarp-header-logo-red.svg",
            description: "AARP",
        },
        icon: "https://www.aarp.org/etc/everywhere/images/favicon.ico",
    },
    event: {
        owner: {
            singular: "Program",
            plural: "Programs",
            singularWithArticle: "a Program",
        },
        type: {
            singular: "Event",
            plural: "Events",
            singularWithArticle: "an Event",
        },
        audience: {
            singular: "Attendee",
            plural: "Attendees",
            singularWithArticle: "an Attendee",
        },
    },
    footerLinks: [
        {
            text: "AARP Privacy Policy",
            url: "https://www.aarp.org/about-aarp/privacy-policy",
        },
        { text: "MAX Privacy Policy", url: "https://www.max.live/privacy-policy" },
    ],
    fanAppUrl: `https://aarpevents.org`,
    onsiteAppUrl: `https://onsite.aarpevents.org`,
    privacyPolicyUrl: "https://www.aarp.org/about-aarp/privacy-policy",
    regionsCollection: "towne_regions",
    menu: {
        sections: {
            engagement: {
                header: "User Engagement",
                items: {
                    live: {
                        label: "AARP.Live",
                        icon: "",
                    },
                },
            },
        },
    },
    theme: {
        colors,
        layout: {
            menuBar: {
                background: colors.background,
                color: colors.main,
            },
            sideBar: {
                background: colors.componentBackground,
                color: colors.text,
                active: {
                    background: colors.main,
                    color: "white",
                },
                sectionHeader: {
                    color: colors.text,
                },
            },
            content: {
                background: colors.contentBackground,
                backgroundHeader: {
                    background: "linear-gradient(180deg, #79201A 20%, #df9793 85%)",
                },
            },
            footer: {
                background: colors.background,
                color: colors.text,
            },
            boxShadowColor: colors.border,
        },
        pages: {
            dashboard: {
                cards: {
                    nextShow: {
                        background: colors.background,
                        color: colors.main,
                    },
                    fanContacts: {
                        background: colors.background,
                        color: colors.main,
                    },
                    fanProfile: {
                        background: "gray",
                        color: "white",
                    },
                    shows: {
                        background: `linear-gradient(180deg, ${colors.main} -20%, ${colors.secondary} 110%)`,
                    },
                },
            },
            contacts: {
                table: {
                    row: {
                        hover: {
                            background: "#fde7e5",
                        },
                        border: { color: "#adb5bd" },
                    },
                },
                noContacts: {
                    background: colors.componentBackground,
                    icon: {
                        color: colors.main,
                    },
                },
            },
            reporting: {
                setlive: {
                    fanReportCard: {
                        background: colors.contentBackground,
                    },
                },
            },
            account: {
                header: {
                    background: `linear-gradient(180deg, ${colors.main} 0%, ${colors.secondary} 100%), #20222A`,
                },
            },
            signup: {
                content: {
                    background: "linear-gradient(155deg, #A50D00, #080a12 55%)",
                },
            },
            region: {
                cards: {
                    contactsTotal: {
                        background: `linear-gradient(180deg, ${colors.main} -20%, ${colors.secondary} 110% 110%)`,
                        color: "white",
                    },
                    contactsLast30Days: {
                        background: `linear-gradient(180deg, ${colors.main} -20%, ${colors.secondary} 110%)`,
                        color: "white",
                    },
                },
            },
        },
        charts: {
            bar: {
                bar: { background: "#d8d8d8" },
            },
            donut: {
                colors: [
                    colors.main,
                    colors.secondary,
                    "#A50D00",
                    "#551612",
                    "#F25A4D",
                    "#A1635F",
                ],
            },
        },
        icon: {
            background: colors.contentBackground,
        },
    },
};
