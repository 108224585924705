import { H3 } from "melodies-source/Text";
import { NoData, ReportMetricCard } from ".";
import { PieChart } from "Components/PieChart";
import { useIsMobile } from "melodies-source/utils";

interface TopSourcesProps {
  sources?: any;
}

export const TopSources = ({ sources, ...props }: TopSourcesProps) => {
  const isMobile = useIsMobile();
  return (
    <ReportMetricCard isElevated {...props}>
      <H3>Top Sources</H3>
      {sources?.length ? (
        <PieChart
          dataKey="value"
          nameKey="label"
          data={sources}
          layout={isMobile ? "vertical" : "horizontal"}
        />
      ) : (
        <NoData />
      )}
    </ReportMetricCard>
  );
};
