import styled from "styled-components";
import ServiceCard from "./ServiceCard";

export const ServiceCards = () => {
  return (
    <ServiceCardsContainer>
      <ServiceCard
        headerText="Livestream"
        bodyText="Take the stage for a truly LIVE experience with real-time interactions and gamified revenue opportunities."
        buttonText="Schedule Now"
        onButtonClick={() =>
          window.open(
            "mailto:support@set.live?subject=Schedule%20a%20Livestream",
          )
        }
      />
    </ServiceCardsContainer>
  );
};

const ServiceCardsContainer = styled.div`
  display: flex;
  margin: 36px 46px 48px;
  gap: 20px;
  max-width: 1300px;

  ${({ theme }) => theme.media.tablet} {
    gap: 10px;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
  }

  ${({ theme }) => theme.media.mobile} {
    align-items: center;
    margin: 36px 0;
  }
`;
