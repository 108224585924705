import { FlexColumn, FlexRow } from "Components/Flex";
import { Body1, H3, Td } from "melodies-source/Text";
import styled from "styled-components";
import { NoData, ReportMetricCard } from ".";
import { ReactComponent as IconLinkAlt } from "assets/svg/link-alt.svg";
import { Metric } from "../aggregate";

interface TopLinksProps {
  metrics?: Metric[];
}

export const TopLinks = ({ metrics, ...props }: TopLinksProps) => {
  return (
    <ReportMetricCard isElevated {...props}>
      <H3>Top Links</H3>
      <FlexColumn gap="20px">
        {metrics?.length ? (
          metrics?.map((link) => (
            <TopLink key={`top-link-${link.id}`}>
              <IconCircle>
                <IconLinkAlt />
              </IconCircle>
              <Body1>{link.label}</Body1>
              <Td>{link.clicks.toLocaleString()}</Td>
            </TopLink>
          ))
        ) : (
          <NoData />
        )}
      </FlexColumn>
    </ReportMetricCard>
  );
};

const TopLink = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr 60px;
  align-items: center;
  gap: 12px;
  padding-right: 12px;
  ${Body1} {
    font-weight: 500;
  }
  ${Td} {
    color: #666;
    text-align: right;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    grid-template-columns: 24px 1fr 40px;
    ${Body1} {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

const IconCircle = styled(FlexRow)`
  background-color: var(--hover-background-color);
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 48px;
  width: 48px;
  flex-shrink: 0;
  color: #7666ad;

  svg {
    width: 20px;
    height: 20px;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 24px;
    height: 24px;

    svg {
      width: 14px;
      height: 14px;
    }
  }
`;
