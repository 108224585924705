import { H1 } from "melodies-source/Text";
import styled from "styled-components";

interface PageContentWrapperProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "title"> {
  title: React.ReactNode;
  header?: React.ReactNode;
}

export const PageContentWrapper = ({
  children,
  title,
  header,
  ...props
}: PageContentWrapperProps) => {
  return (
    <PageContentWrapperContainer {...props}>
      <HeaderContainer>
        <Title>{title}</Title>
        {header}
      </HeaderContainer>
      {children}
    </PageContentWrapperContainer>
  );
};

const Title = styled(H1)`
  color: #ffffff;
  ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 26px;
    line-height: 36px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-bottom: 20px;
  }
`;

const PageContentWrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 32px 32px 42px;
  flex-grow: 1;

  ${({ theme }) => theme.media.mobile} {
    padding: 20px 0;
    margin: 0 20px;
  }
`;
