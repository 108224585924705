"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getConfigFromHost = exports.companies = void 0;
const gtb_1 = require("./companies/gtb");
const fizz_1 = require("./companies/fizz");
const towne_1 = require("./companies/towne");
const aarp_1 = require("./companies/aarp");
exports.companies = [
    [/gtbevents/, gtb_1.gtb],
    [/fizz/, fizz_1.fizz],
    [/towne/, towne_1.towne],
    [/aarp/, aarp_1.aarp],
];
const getConfigFromHost = (host, config) => {
    for (const [k, v] of config) {
        if (host.match(k)) {
            return v;
        }
    }
    return undefined;
};
exports.getConfigFromHost = getConfigFromHost;
